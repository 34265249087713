// Connection Mixin
export default {
    methods: {
        // Checking Connection Count
        async connectionsCount(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post("/connections/user_connection_count", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        // Sending Connect Request API
        async sendBusinessRequestAPI(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post("/connections/add_connection", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
    }
}